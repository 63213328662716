<template>
  <div>
    <b-table
      v-if="billingData.length"
      ref="refBillingDNTable"
      class="position-relative event_list shadow-table"
      :items="billingData"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      no-sort-reset
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >
      <!-- <template #empty="">
        <b-row>
          <b-col>
            <div class="empty_block">
              <h4>No billings / debit notes found.</h4>
            </div>
          </b-col>
        </b-row>
      </template> -->
      <template #cell(trxNo)="data">
        <div
          v-if="data.item.type.toLowerCase() == 'invoice'"
          class="text-nowrap"
        >
          <b-button
            v-if="canViewThisAction('show', 'Invoice')"
            variant="link"
            class="p-0 weight-600"
            :to="{ name: 'customers-invoices-show', params: { id: data.item.trxID } }"
          >
            {{ data.item.trxNo }}
          </b-button>
          <span
            v-else
            class=""
          >{{ data.item.trxNo }}</span>
        </div>
        <div
          v-else
          class="text-nowrap"
        >
          <span class="weight-600">{{ data.item.trxNo }}</span>
        </div>
      </template>
      <template #cell(amount)="data">
        <div class="text-nowrap">
          <span class="">${{ numberFormat(data.item.amount) }}</span>
        </div>
      </template>
      <template #cell(createdAt)="data">
        <div class="text-nowrap">
          <span class="">{{ data.item.createdBy ? data.item.createdBy : '' }}</span>
          <br>
          <span class="">{{ data.item.createdAt ? dateFormatWithTime(data.item.createdAt) : '' }}</span>
        </div>
      </template>
      <template #bottom-row="">
        <td
          class="bottom-row"
          colspan="6"
        >
          <span class="weight-500 fs-18">Total Debit {{ getTotal() }}</span>
        </td>
      </template>
    </b-table>
    <div
      v-else
      class="empty_block"
    >
      <h4>No billings / debit notes found.</h4>
    </div>
  </div>
</template>

<script>
import {
  BTable, BButton, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BTable,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    invoices: {
      type: [Array, null],
      default: () => [],
    },
    ledgerSummary: {
      type: [Object, null],
      default: () => {},
    },
  },
  data() {
    return {
      billingData: [],
      user: store.state.auth.userData,
      render: false,
      sortBy: 'createdAt',
      sortDesc: false,
      isSortDirDesc: true,
      tableItems: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      tableColumns: [
        {
          key: 'trxNo',
          label: 'TRX No.',
          sortable: true,
        },
        {
          key: 'type',
          label: 'Type',
          sortable: true,
        },
        {
          key: 'item',
          label: 'Item',
          sortable: true,
        },
        {
          key: 'amount',
          label: 'amount',
          sortable: true,
        },
        {
          key: 'remarks',
          label: 'Remarks',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'Created by',
          sortable: true,
        },
      ],
    }
  },

  created() {
    if (this.invoices) {
      this.invoices.forEach(item => {
        item.billings.forEach(bill => {
          this.billingData.push({
            trxID: item.trxID,
            trxNo: item.trxNo,
            type: item.type,
            item: bill.item,
            amount: bill.amount,
            remarks: item.remarks,
            createdAt: item.createdAt,
            createdBy: item.createdBy,
          })
        })
      })
    }
  },

  methods: {
    canViewThisAction,
    resolveFileStatusVariant(status) {
      if (status === 'OPEN') return 'warning'
      if (status === 'BOOK') return 'info'
      return 'success'
    },
    splitNewLine(text, index) {
      const separateLines = text.split(/\r?\n|\r|\n/g)
      if (separateLines[index]) {
        return separateLines[index]
      }

      return ''
    },
    getTotal() {
      const total = this.billingData.reduce((n, { amount }) => n + Number(amount), 0)
      if (total) {
        return `$${this.numberFormat(total)}`
      }

      return '-'
    },
  },
}
</script>
